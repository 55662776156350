import classes from "./Range.module.css";

export default function Range({
  tripPrice,
  value,
  setValue,
  customerAbility,
  checkCustomerAbility,
}) {
  const handleChange = (event) => {
    setValue(parseInt(event.target.value, 10));
    checkCustomerAbility();
  };

  return (
    <div>
      <div className={classes.rangeInput}>
        <input
          type="range"
          required
          name="installmentsMonths"
          id="installmentsMonths"
          min={2}
          max={15}
          value={value}
          onChange={handleChange}
        />
        <span className={classes.currentValue}>{value} months</span>
      </div>
      <span
        className={`${classes.mathResult} ${
          !customerAbility ? classes.invaildValue : ""
        }`}
      >
        {(tripPrice / value).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}{" "}
        IQD per month
      </span>
    </div>
  );
}
