import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

// * Our components
import InformationBox from "../../components/ui/InformationBox";

// * Styles
import classes from "./ExploreTrips.module.css";
import Loader from "../../components/ui/Loader";

const getRandomImage = () => {
  // Placeholder image URLs
  const images = [
    "https://picsum.photos/2300",
    "https://picsum.photos/2200",
    "https://picsum.photos/2100",
    "https://picsum.photos/2000",
    "https://picsum.photos/1900",
    "https://picsum.photos/1800",
    "https://picsum.photos/1700",
    "https://picsum.photos/1600",
    "https://picsum.photos/1500",
    "https://picsum.photos/1400",
    "https://picsum.photos/1300",
    "https://picsum.photos/1200",
    "https://picsum.photos/1100",
    "https://picsum.photos/1000",
    "https://picsum.photos/900",
    "https://picsum.photos/800",
    "https://picsum.photos/700",
    "https://picsum.photos/600",
    "https://picsum.photos/500",
    "https://picsum.photos/400",
    "https://picsum.photos/300",
    // Add more placeholder image URLs as needed
  ];

  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

export default function ExploreTrips() {
  const [trips, setTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { pageTitle, tripsType, dealsType } = location.state || {};

  console.log(dealsType);
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/search/trips`,
          { tripType: tripsType, dateFrom: dealsType }
        );
        if (response.status === 200) {
          setTrips(response.data);
        } else {
          console.error("Error:", response.statusText);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error("Error:", error);
      }
    };
    fetchTrips();
  }, []);

  return (
    <section className={classes.page}>
      <h2 className={classes.pageTitle}>{pageTitle}</h2>
      {isLoading && <Loader />}
      {trips.length ? (
        <div className={classes.tripsContainer}>
          {trips.map((trip, index) => (
            <Link
              to={`/trip/${trip.id}`}
              key={trip.id}
              className={classes.tripCard}
            >
              <div className={classes.tripImage}>
                <img src={getRandomImage()} alt={`Trip ${index + 1}`} />
              </div>
              <div className={classes.tripText}>
                <div className="titles">
                  <h2 className={classes.tripTitle}>{trip.name}</h2>
                  <h3 className={classes.tripSubtitle}>{trip.type} Trip</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <>{!isLoading && <InformationBox>No trips found</InformationBox>}</>
      )}
    </section>
  );
}
