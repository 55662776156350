import classes from "./ErrorBox.module.css";

export default function ErrorBox({ children }) {
  return (
    <div className={classes.errorBox}>
      <img src="/assets/img/ErrorConfirmation/error.svg" alt="Error" />
      <p>{children}</p>
    </div>
  );
}
