// Logout.js
import React from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useAuth } from "../hooks/useAuth";

const Logout = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  const { loginUsing } = useAuth();

  const handleLogout = async () => {
    try {
      // Perform any necessary cleanup on the client side (e.g., remove tokens, clear local storage)
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      localStorage.removeItem("logo");
      setIsAuthenticated(false);

      // Example: Make a request to the server to log out (optional)
      await axios.post(`${process.env.REACT_APP_URL}/logout`);
      // await axios.post('https://safratake-backend.netlify.app/.netlify/functions/logout');

      // Redirect to login page after logout
      navigate("/login");
      loginUsing(null);
      console.log("Logout successful");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className="body-content">
      <Container>
        <h2>Logout</h2>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Container>
    </div>
  );
};

export default Logout;
