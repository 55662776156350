import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import axios, { all } from "axios";

import Input from "./ui/Input";
import InformationBox from "./ui/InformationBox";
import { SwitchIcon } from "../assets";
import { summerDealsImage } from "../assets";
import { tourismDealsImage } from "../assets";
import Image from "./ui/Image";

import "./Feed.css";
// Function to get random placeholder image
const getRandomImage = () => {
  // Placeholder image URLs
  const images = [
    "https://picsum.photos/2300",
    "https://picsum.photos/2200",
    "https://picsum.photos/2100",
    "https://picsum.photos/2000",
    "https://picsum.photos/1900",
    "https://picsum.photos/1800",
    "https://picsum.photos/1700",
    "https://picsum.photos/1600",
    "https://picsum.photos/1500",
    "https://picsum.photos/1400",
    "https://picsum.photos/1300",
    "https://picsum.photos/1200",
    "https://picsum.photos/1100",
    "https://picsum.photos/1000",
    "https://picsum.photos/900",
    "https://picsum.photos/800",
    "https://picsum.photos/700",
    "https://picsum.photos/600",
    "https://picsum.photos/500",
    "https://picsum.photos/400",
    "https://picsum.photos/300",
    // Add more placeholder image URLs as needed
  ];

  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const Feed = () => {
  // * data
  const [trips, setTrips] = useState([]);
  const [searched, setSearched] = useState(false);
  const [activeCompanies, setActiveCompanies] = useState([]);

  // * Search fields states
  const [tripType, setTripType] = useState("All");
  const [contextMenu, setContextMenu] = useState(false);
  const [tripTypes, setTripTypes] = useState([
    "All",
    "Tourism",
    "Therapeutic",
    "Religious",
    "Studying",
  ]);
  const [fromLocation, setFromLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [dateFrom, setDateFrom] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dateTo, setDateTo] = useState("");

  const [travelAgency, setTravelAgency] = useState("");
  const [travelAgencyCity, setTravelAgencyCity] = useState("");
  const [travelAgencyAddress, setTravelAgencyAddress] = useState("");

  // * Tabs
  const [activeTab, setActiveTab] = useState("trips");

  const [currentDeals, setCurrentDeals] = useState(null);

  const tripsSearchQuery = {
    tripType,
    fromLocation,
    destination,
    dateFrom,
    dateTo,
  };

  const companiesSearchQuery = {
    travelAgency,
    travelAgencyCity,
    travelAgencyAddress,
  };

  const switchRef = useRef(null);

  // Fetch trips
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/trips`);
        setTrips(response.data || []);
      } catch (error) {
        console.error("Failed to fetch trips", error);
      }
    };
    fetchTrips();
  }, [activeTab]);

  // Fetch Active companies
  useEffect(() => {
    const fetchActiveCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/companies/active`
        );
        setActiveCompanies(response.data || []);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchActiveCompanies();
  }, [activeTab]);

  console.log(activeTab);

  // Function to handle form submission for searching (Trips, Companies)
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(dateFrom);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/search/${activeTab}`,
        activeTab === "trips" ? tripsSearchQuery : companiesSearchQuery
      );
      console.log("Search successful", response.data);
      if (response.status === 200) {
        if (activeTab === "trips") {
          setTrips(response.data);
        } else {
          setActiveCompanies(response.data);
        }
        setSearched(true);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function handleChangeType(e) {
    const value = e.target.innerHTML;
    setTripType(value);
    setContextMenu(false);
  }

  function handleSwitchLocations() {
    const temp = fromLocation;
    setFromLocation(destination);
    setDestination(temp);
    switchRef.current.classList.toggle("change-dir");
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // * Handle Context Menu
  const handleCloseContextMenu = () => {
    setContextMenu(false);
  };

  const handleOpenContextMenu = () => {
    setContextMenu(true);
  };

  // * Deals types

  function getDealsType() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    let dateFrom = new Date(currentDate);

    const formatDate = (date) => {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    };

    if (currentMonth >= 4 && currentMonth < 10) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "عروض الصيف",
        type: "summer",
      };
    } else if (currentMonth >= 10 || currentMonth < 1) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "الرحلات الشتوية",
        type: "winter",
      };
    } else if (currentMonth >= 1 && currentMonth < 4) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "الرحلات الربيعية",
        type: "spring",
      };
    } else {
      return "unexpected results";
    }
  }

  useEffect(() => setCurrentDeals(getDealsType()), []);

  return (
    <div className="feed-container">
      <div className="banner">
        <h1>Find a holiday</h1>
        <div className="tabs">
          <div className="btn-group">
            <button
              className={`btn ${activeTab === "trips" ? "active" : ""}`}
              onClick={() => handleTabChange("trips")}
            >
              Trips
            </button>
            <button
              className={`btn ${activeTab === "companies" ? "active" : ""}`}
              onClick={() => handleTabChange("companies")}
            >
              Travel Agencies
            </button>
          </div>
        </div>

        {contextMenu && (
          <div className="overlay" onClick={handleCloseContextMenu}></div>
        )}
        {activeTab === "trips" && (
          <div className="context-menu-container">
            <h3 onClick={handleOpenContextMenu}>
              <span>Trip type: {tripType}</span>
              <ArrowDropDown />
            </h3>

            {contextMenu && (
              <div className="context-menu">
                {tripTypes.map((type, index) => (
                  <span
                    key={index}
                    value={type}
                    className={`menu-item ${type === tripType ? "active" : ""}`}
                    onClick={handleChangeType}
                  >
                    {type}
                  </span>
                ))}
                {/* <div className="menu-item active">All</div>
                <div className="menu-item">OTHERS</div> */}
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit} className="search-container">
          <div className="inputs">
            {activeTab === "trips" ? (
              <>
                <section className="form-group">
                  <Input
                    id="fromLocation"
                    label="From"
                    type="text"
                    value={fromLocation}
                    setValue={setFromLocation}
                    responsive={true}
                  />
                  <div
                    ref={switchRef}
                    className="switch-floating-icon"
                    onClick={handleSwitchLocations}
                  >
                    <img src={SwitchIcon} />
                  </div>
                  <Input
                    id="destination"
                    label="To"
                    type="text"
                    value={destination}
                    setValue={setDestination}
                    responsive={true}
                  />
                </section>
                <section className="form-group">
                  <Input
                    id="dateFrom"
                    label="Date from"
                    type="date"
                    value={dateFrom}
                    setValue={setDateFrom}
                    responsive={true}
                  />
                  <Input
                    id="dateTo"
                    label="Date To"
                    type="date"
                    value={dateTo}
                    setValue={setDateTo}
                    responsive={true}
                  />
                </section>
              </>
            ) : (
              <>
                <section className="form-group">
                  <Input
                    id="travelAgency"
                    label="Travel agency"
                    type="text"
                    value={travelAgency}
                    setValue={setTravelAgency}
                    responsive={true}
                    required
                  />
                  <Input
                    id="travelAgencyCity"
                    label="City (optional)"
                    type="text"
                    value={travelAgencyCity}
                    setValue={setTravelAgencyCity}
                    responsive={true}
                  />
                  <Input
                    id="travelAgencyAddress"
                    label="Address (optional)"
                    type="text"
                    value={travelAgencyAddress}
                    setValue={setTravelAgencyAddress}
                    responsive={true}
                  />
                </section>
              </>
            )}
          </div>
          <button type="submit" className="search-button">
            Search
          </button>
        </form>
      </div>

      <div className="big-image-container">
        <div className={`big-image-container-img ${currentDeals?.type}`}></div>
        <div className="big-image-container-body">
          <h2 className="big-image-container-title">{currentDeals?.title}</h2>
          <p className="big-image-container-subtitle">
            إستكشف عروض الصيف مع خصومات رائعة وتجارب لا تُنسى
          </p>
          <div className="big-image-actions-container">
            <Link
              to="/exploreTrips"
              state={{
                dealsType: currentDeals?.dateFrom,
                pageTitle: currentDeals?.title,
              }}
              className="big-image-container-button"
            >
              إستكشف
            </Link>
          </div>
        </div>
      </div>

      <h2 className="section-title">Trips</h2>
      {trips.length ? (
        <div className="trips-container">
          {trips.map((trip, index) => (
            <Link to={`/trip/${trip.id}`} key={trip.id} className="trip-card">
              <div className="image">
                <img src={getRandomImage()} alt={`Trip ${index + 1}`} />
              </div>
              <div className="text">
                <div className="titles">
                  <h2>{trip.name}</h2>
                  <h3>{trip.type} Trip</h3>
                </div>
                {/* <p className="price-label">{trip.price} IQD</p> */}
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <InformationBox>No trips found</InformationBox>
      )}

      <div className="big-image-container">
        <div className="big-image-container-img tourism"></div>
        <div className="big-image-container-body">
          <h2 className="big-image-container-title">الرحلات السياحية</h2>
          <p className="big-image-container-subtitle">
            إستكشف عروض الرحلات السياحية مع خصومات رائعة وتجارب لا تُنسى
          </p>
          <div className="big-image-actions-container">
            <Link
              to="/exploreTrips"
              state={{ tripsType: "tourism", pageTitle: "الرحلات السياحية" }}
              className="big-image-container-button"
            >
              إستكشف
            </Link>
          </div>
        </div>
      </div>

      <h2 className="section-title">Travel Agencies</h2>
      <div className="horizontal-slider-container">
        {activeCompanies.length ? (
          <div className="horizontal-slider">
            <div className="companies-container">
              {activeCompanies?.map((company) => {
                return (
                  <Link
                    className="company-card"
                    key={company.id}
                    to={`/company/${company.id}`}
                  >
                    <Image imagePath={company?.logo} className="avatar" />
                    <div className="company-info">
                      <h3>{company.username}</h3>
                      <p>{company.city}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <InformationBox>No companies found</InformationBox>
        )}
      </div>

      <h2 className="section-title">Trending cities</h2>
      <div className="cities-container">
        {/* City 1 */}
        <div className="city-card">
          <img src="https://picsum.photos/300" alt="City 1" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>London</p>
          </div>
        </div>

        {/* City 2 */}
        <div className="city-card">
          <img src="https://picsum.photos/301" alt="City 2" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>Sydney</p>
          </div>
        </div>

        {/* City 3 */}
        <div className="city-card">
          <img src="https://picsum.photos/302" alt="City 3" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>Paris</p>
          </div>
        </div>

        {/* City 4 */}
        <div className="city-card">
          <img src="https://picsum.photos/303" alt="City 4" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>Stockholm</p>
          </div>
        </div>

        {/* City 5 */}
        <div className="city-card">
          <img src="https://picsum.photos/304" alt="City 5" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>Oslo</p>
          </div>
        </div>

        {/* City 6 */}
        <div className="city-card">
          <img src="https://picsum.photos/305" alt="City 6" />
          <div className="city-info">
            <h3>FIND TRIPS TO</h3>
            <p>Moscow</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
