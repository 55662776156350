// src/components/Register.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  FormControl,
  TextareaAutosize,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

import { useCountries } from "../../hooks/useCountries";
import { useImage } from "../../hooks/useImage";

import classes from "./AgencyRegister.module.css";
import ErrorBox from "../../components/ui/ErrorBox";
import InformationBox from "../../components/ui/InformationBox";

const AgencyRegister = () => {
  // * Form States
  const [formData, setFormData] = useState({
    userType: "company",
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    description: "",
    website: "",
  });
  const [additionalPhone, setAdditionalPhone] = useState({
    active: false,
    value: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  // * Custom Hook
  const {
    countries,
    country,
    setCountry,
    cities,
    setCities,
    city,
    setCity,
    address,
    setAddress,
  } = useCountries();

  // * Custom Hook
  const { image, handlePrepareImage } = useImage();

  const [companyDocs, setCompanyDocs] = useState(null);

  const [error, setError] = useState("");

  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    isSucceed: false,
    msg: "",
  });

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (c) => c.country === selectedCountry
    );
    if (selectedCountryData) {
      setCountry(selectedCountry);

      setCities(selectedCountryData.cities);
    }
  };

  const navigate = useNavigate();

  const [infoBox, setInfoBox] = useState({ msg: "", show: false });

  const handleRegister = async (e) => {
    e.preventDefault();

    if (
      !formData.username ||
      !formData.email ||
      !formData.password ||
      !formData.phoneNumber ||
      !country ||
      !city ||
      !address ||
      !companyDocs
    ) {
      setFormStatus((prev) => ({ ...prev, isSubmit: true }));
      return;
    } else {
      setFormStatus((prev) => ({ ...prev, isSubmit: true, isSucceed: true }));
    }

    try {
      let companyData = {
        ...formData,
        additionalPhone: additionalPhone.value,
        country: country,
        city: city,
        address: address,
      };

      let uploadImageResponse = null;
      if (image) {
        const companyLogo = new FormData();
        companyLogo.append("image", image.imageFile);
        companyLogo.append("imageType", image.imageType);

        uploadImageResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadImage`,
          companyLogo
        );
      }

      const companyDocuments = new FormData();

      companyDocuments.append("pdfType", `${formData.username} documents`);

      companyDocs.forEach((document) => {
        companyDocuments.append("pdf", document);
      });

      const uploadPDFResponse = await axios.post(
        `${process.env.REACT_APP_URL}/uploadPDF`,
        companyDocuments
      );

      // console.log(uploadPDFResponse.data.pdfPath);

      companyData = {
        ...companyData,
        logo: uploadImageResponse?.data?.imagePath || "",
        docs: `${uploadPDFResponse.data.pdfPaths}`,
      };

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        companyData
      );

      console.log("Register successful", registerResponse.data);
      setFormStatus((prev) => ({ ...prev, isSucceed: true }));
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setInfoBox({ msg: "You already have an account", show: true });
      } else if (error.request) {
        console.log(error.request);
        setError("Please, check your connection");
      } else {
        console.log("Error", error.message);
        setError("An error occurred");
      }
    }
  };

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div className={classes.agencyRegister}>
          <Typography variant="h4" align="center" gutterBottom>
            Registration
          </Typography>
          <form>
            <TextField
              required
              label="Company name"
              fullWidth
              error={formStatus.isSubmit && !formData.username}
              margin="normal"
              value={formData.username}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, username: e.target.value }))
              }
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              required
              error={formStatus.isSubmit && !formData.email}
              margin="normal"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              required
              error={formStatus.isSubmit && !formData.password}
              margin="normal"
              value={formData.password}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, password: e.target.value }))
              }
            />
            <TextField
              label="Confirm password"
              type="password"
              fullWidth
              required
              error={
                (formStatus.isSubmit && !confirmPassword) ||
                confirmPassword !== formData.password
              }
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <TextField
              label="Phone number"
              type="text"
              fullWidth
              required
              error={formStatus.isSubmit && !formData.phoneNumber}
              margin="normal"
              value={formData.phoneNumber}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  phoneNumber: e.target.value,
                }));
              }}
            />
            {!additionalPhone.active ? (
              <div className={classes.buttonContainer}>
                <button
                  className={classes.additionalPhoneButton}
                  type="button"
                  onClick={() =>
                    setAdditionalPhone((prev) => ({
                      ...prev,
                      active: true,
                    }))
                  }
                >
                  Add another phone
                </button>
              </div>
            ) : (
              <TextField
                label="Additional Phone number"
                type="text"
                fullWidth
                margin="normal"
                value={additionalPhone.value}
                onChange={(e) =>
                  setAdditionalPhone((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
              />
            )}

            <TextareaAutosize
              minRows={3}
              placeholder="Company description"
              size="md"
              variant="outlined"
              className={classes.textArea}
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Country</InputLabel>
              <Select
                fullWidth
                id="country"
                required
                error={formStatus.isSubmit && !country}
                name="country"
                value={country}
                onChange={handleCountryChange}
              >
                {countries?.map((el) => (
                  <MenuItem key={el.iso2} value={el.country}>
                    {el.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>City</InputLabel>
              <Select
                fullWidth
                error={formStatus.isSubmit && !city}
                id="city"
                required
                value={city}
                name="city"
                onChange={(e) => setCity(e.target.value)}
              >
                {cities?.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <input
              type="text"
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address*"
              className={`${classes.customInput} ${
                formStatus.isSubmit && !address && classes.invalidValue
              }`}
            />
            <input
              type="text"
              value={formData.website}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, website: e.target.value }))
              }
              placeholder="Website"
              className={classes.customInput}
            />
            <div className={classes.inputContainer}>
              <input
                accept="image/*"
                type="file"
                onChange={(e) =>
                  handlePrepareImage(e, `${formData.username} company logo`)
                }
                className={classes.fileInput}
                name="image"
                id="logoInput"
              />
              <label htmlFor="logoInput">
                <p className={classes.customFileInputButton}>
                  <span>Company logo:</span>
                  <img src="assets/img/trip/upload.svg" />
                </p>
              </label>
              {/* {logo && <span className={classes.fileName}>{logo.name}</span>} */}
            </div>
            <div
              className={`${classes.inputContainer} ${
                formStatus.isSubmit && !companyDocs && classes.invalidValue
              }`}
            >
              <input
                type="file"
                required
                onChange={(e) => setCompanyDocs(Array.from(e.target.files))}
                className={classes.fileInput}
                id="companyDocsInput"
                multiple
              />
              <label htmlFor="companyDocsInput">
                <p className={classes.customFileInputButton}>
                  <span>Company document:</span>
                  <img src="assets/img/trip/upload.svg" />
                </p>
              </label>
            </div>

            {formStatus.isSubmit && !formStatus.isSucceed && (
              <ErrorBox>{error || "Please check your credentials"}</ErrorBox>
            )}
            {infoBox.show && (
              <InformationBox>
                {infoBox.msg}{" "}
                <Link to="/login" className={classes.linkButton}>
                  Login
                </Link>
              </InformationBox>
            )}

            <button className={classes.registerButton} onClick={handleRegister}>
              Register
            </button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AgencyRegister;
