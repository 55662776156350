import classes from "./AdminPanel.module.css";
import { useAuth } from "../../hooks/useAuth";

import Card from "../../components/ui/Card";

import { Container } from "@mui/material";

// * Icons
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";

export default function AdminPanel() {
  const { user, admins } = useAuth();

  return (
    <section className={classes.pageBody}>
      <Container maxWidth="lg">
        {!admins.includes(user?.userType) ? (
          <h2>You are not allowed to access this page</h2>
        ) : (
          <>
            <div className={classes.cards}>
              <Card collapsable={false} Element={Link} to="createUser">
                <h3>
                  Create User <PersonAddIcon fontSize="large" />
                </h3>
              </Card>
              <Card collapsable={false} Element={Link} to="/dashboard">
                <h3>
                  Delete user <PersonRemoveIcon fontSize="large" />
                </h3>
              </Card>
              <Card collapsable={false} Element={Link} to="/safra-page">
                <h3>
                  Create Trip <AddIcon fontSize="large" />
                </h3>
              </Card>
              <Card collapsable={false} Element={Link} to="deleteTrip">
                <h3>
                  Delete Trip <RemoveIcon fontSize="large" />
                </h3>
              </Card>
            </div>
          </>
        )}
      </Container>
    </section>
  );
}
