import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";

import { useAuth } from "../../hooks/useAuth";

import classes from "./ViewCustomerProfile.module.css";
import ErrorMessage from "../../components/ui/ErrorMessage";
import Modal from "../../components/ui/Modal";

import { ProfileCover } from "../../assets";

const ViewCustomerProfile = () => {
  const [customerData, setCustomerData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    day: "",
    month: "",
    year: "",
    country: "",
    city: "",
    address: "",
  });

  const { id } = useParams();

  const navigate = useNavigate();

  const { user, admins } = useAuth();

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    confirmDelete: false,
  });

  const [passwordToDeleteAccount, setPasswordToDeleteAccount] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);

  const [contextMenu, setContextMenu] = useState(null);

  // const [customerTrips, setCustomerTrips] = useState([]);

  const handleDeleteModal = () => {
    setDeleteModal((prev) => ({ ...prev, show: !prev.show }));
  };

  const handleDeleteCustomer = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL}/customer/${id}`, {
        data: {
          adminId: user?.userId,
          adminPass: passwordToDeleteAccount,
          adminType: user?.userType,
        },
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to reject the company request", error);
      setWrongPassword(true);
    }
  };

  const handleMore = (event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 5,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/customer/${id}`
        );
        setCustomerData(response.data || []);
      } catch (error) {
        console.error("Failed to fetch the Customer data", error);
      }
    };

    fetchCustomer();
  }, []);

  // useEffect(() => {
  //   const fetchCompanyTrips = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_URL}/company/${id}/trips`
  //       );
  //       setCompanyTrips(res.data || []);
  //     } catch (error) {
  //       console.error(`Failed to Fetch Company trips`, error);
  //     }
  //   };
  //   fetchCompanyTrips();
  // }, []);

  const cardRef = useRef(null);

  const smoothScrolling = () => {
    cardRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className={classes.profile}>
      <Container className={classes.card}>
        <section className={classes.header}>
          <h2 className={classes.profileGreeting}>
            <span className={classes.name}>
              {customerData.username}
            </span>
            <button className={classes.link} onClick={smoothScrolling}>
              Previous trips: "STATIC"
            </button>
          </h2>
          <img
            src={ProfileCover}
            alt="profile cover"
            className={classes.coverImage}
          />
        </section>
        <section className={classes.content}>
          <img
            src="https://picsum.photos/200"
            alt="profile"
            className={classes.avatar}
          />

          <div className={classes.contentRow}>
            <h3 className={classes.rowTitle}>General Info</h3>

            <div className={classes.readingMode}>
              <p>
                <span className={classes.infoTitle}>FullName: </span>
                <span>{customerData.username}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>Email: </span>
                <span>{customerData.email}</span>
              </p>

              <p>
                <span className={classes.infoTitle}>Phone Number: </span>
                <span>{customerData.phoneNumber}</span>
              </p>
            </div>
            {admins.includes(user?.userType) && (
              <>
                <img
                  src="/assets/img/company/more-icon.svg"
                  className={classes.moreIcon}
                  alt="more"
                  onClick={handleMore}
                />
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleDeleteModal}
                  >
                    Delete Customer
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>

          <div className={classes.contentRow}>
            <div className={classes.readingMode}>
              <h3 className={classes.rowTitle}>Address</h3>
              <p>
                <span className={classes.infoTitle}>Country: </span>
                <span>{customerData.country}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>City: </span>
                <span>{customerData.city}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>Address: </span>
                <span>{customerData.address}</span>
              </p>
            </div>
          </div>

          {deleteModal.show && (
            <Modal toggleModal={setDeleteModal} className={classes.modal}>
              <h3 className={classes.modalHeading}>
                Are you sure that you want to delete the Customer
              </h3>
              <div className={classes.inputContainer}>
                <label className={classes.inputLabel}>
                  Type your password please:
                </label>
                <input
                  type="password"
                  value={passwordToDeleteAccount}
                  onChange={(e) => {
                    setPasswordToDeleteAccount(e.target.value);
                    setWrongPassword(false);
                  }}
                />
              </div>
              {wrongPassword && (
                <ErrorMessage>You typed a wrong password</ErrorMessage>
              )}
              <div className={`${classes.btnGroup}`}>
                <button
                  className={`btn ${classes.redButton}`}
                  onClick={() =>
                    setDeleteModal({
                      show: false,
                      confirmDelete: false,
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className={`btn ${classes.deleteAccountButton}`}
                  onClick={handleDeleteCustomer}
                >
                  Delete
                </button>
              </div>
            </Modal>
          )}
        </section>
      </Container>
      {/* {companyTrips.length ? (
        <Card cardRef={cardRef} cardTitle="Trips" showMore={true}>
          {companyTrips?.map((trip) => (
            <Link
              to={`/trip/${trip.id}`}
              key={trip.id}
              className={classes.trip}
            >
              <h4 className={classes.flexbox}>
                {trip.name}
                <button>Show</button>
              </h4>
              <div className={classes.columnFlexbox}>
                <p>
                  {trip.fromLocation} ➡ {trip.destination}
                </p>
                <p>
                  {trip.dateFrom.slice(0, 10)} - {trip.dateTo.slice(0, 10)}
                </p>
              </div>
            </Link>
          ))}
        </Card>
      ) : null} */}
    </section>
  );
};

export default ViewCustomerProfile;
