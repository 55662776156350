import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const renderStars = (numStars, clickHandler = null, currentRating) => {
  const cursorStatus = clickHandler ? "pointer" : "unset";

  const stars = [];
  for (let i = 1; i <= numStars; i++) {
    if (currentRating >= i) {
      // Full star
      stars.push(
        <span
          key={i}
          style={{ cursor: "pointer", color: "gold" }}
          onClick={clickHandler ? () => clickHandler(i) : null}
        >
          <FontAwesomeIcon icon={faStar} />
        </span>
      );
    } else {
      // Empty star
      stars.push(
        <span
          key={i}
          style={{ cursor: "pointer", color: "gray" }}
          onClick={clickHandler ? () => clickHandler(i) : null}
        >
          <FontAwesomeIcon icon={faStar} />
        </span>
      );
    }
  }
  return stars;
};

export { renderStars };
