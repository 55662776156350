import { useState, useContext, createContext } from "react";

const UserContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();

  return <UserContext.Provider value={auth}>{children}</UserContext.Provider>;
};

export const useAuth = () => useContext(UserContext);

const useProvideAuth = () => {
  const admins = ["developer", "superAdmin", "admin"];
  const roles = [...admins, "company"];

  const [user, setUser] = useState(() => {
    const storedToken = localStorage.getItem("token");
    const storedUserId = localStorage.getItem("userId");
    const storedUsername = localStorage.getItem("username");
    const storedUserType = localStorage.getItem("userType");
    const storedEmail = localStorage.getItem("email");
    const storedLogo = localStorage.getItem("logo");

    return storedToken
      ? {
          token: storedToken,
          userId: storedUserId,
          username: storedUsername,
          userType: storedUserType,
          email: storedEmail,
          logo: storedLogo,
        }
      : null;
  });

  const loginUsing = (userData) => {
    setUser(userData);
  };

  return {
    user,
    loginUsing,
    admins,
    roles,
  };
};

export default useProvideAuth;
