import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField } from "@mui/material";

import classes from "./PaymentModal.module.css";

import { masterCardLogo, qiCardLogo } from "../../assets";
import Loader from "../../components/ui/Loader";
import GradientButton from "../../components/ui/GradientButton";
import Range from "../../components/ui/Range";
import ErrorMessage from "./ErrorMessage";
import ErrorBox from "./ErrorBox";

import { useAuth } from "../../hooks/useAuth";

export default function PaymentModal({
  toggleModal,
  modalPage,
  setModalPage,
  formData,
  trip,
}) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("installments");
  const [customerInfo, setCustomerInfo] = useState({
    cardNumber: "",
    fullName: "",
    phoneNumber: "",
    OTP: "",
  });
  const [loadingPage, setLoadingPage] = useState(false);
  const [customerAbility, setCustomerAbility] = useState(false);
  const [allowableSalary, setAllowableSalary] = useState();
  const [sessionId, setSessionId] = useState("");
  const [installmentsMonths, setInstallmentsMonths] = useState(15);

  const [modalValidation, setModalValidation] = useState(true);

  const headers = {
    Authorization: process.env.REACT_APP_MERCHANT_KEY,
  };

  const handleInputChanges = (e, maxLength) => {
    let numericValue = e.target.value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, maxLength);
    setCustomerInfo((prev) => ({ ...prev, [e.target.name]: limitedValue }));
    setModalValidation(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // * [1] Get Customer Information
  const getCustomerInformation = async () => {
    if (customerInfo.cardNumber) {
      try {
        setLoadingPage(true);

        const res = await axios.post(
          `${process.env.REACT_APP_AQSATI}/GetCustomerInformation`,
          {
            Identity: customerInfo.cardNumber,
          },
          {
            headers,
          }
        );

        setLoadingPage(false);

        if (!res) {
          console.log("Error");
          return;
        }

        setCustomerInfo(res.data.data.customerInformation);
        const allowableSalary =
          res.data.data.paymentInformation.salaryInformation.allowableSalary;

        setAllowableSalary(allowableSalary);

        setSessionId(res.data.data.session.sessionId);

        checkCustomerAbility(allowableSalary);
        setModalPage(2);
      } catch (error) {
        console.error("Failed to get the card");
        setModalValidation(false);
        setLoadingPage(false);
      }
    } else {
      setModalValidation(false);
    }
  };

  const checkCustomerAbility = (allowableSalary) => {
    const minInstallmentAmount = parseInt(
      (trip.tripPrice / installmentsMonths).toFixed(3)
    );

    if (allowableSalary > minInstallmentAmount) {
      setCustomerAbility(true);
    } else {
      setCustomerAbility(false);
    }
  };

  // * [2] Validate Installments
  const validateInstallments = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AQSATI}/ValidatorOfInstallment`,
        {
          sessionId: sessionId,
          amount: parseInt(trip.tripPrice / installmentsMonths),
          countOfMonth: installmentsMonths,
        },
        {
          headers,
        }
      );

      if (!res) {
        console.log("Error");
        return;
      }

      setModalPage(3);
    } catch (error) {
      console.error("An error occur", error);
    }
  };

  // * [3] Validate OTP

  const validateOTP = async () => {
    if (customerInfo.OTP) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_AQSATI}/CreateInstallment`,
          {
            sessionId: sessionId,
            OTP: customerInfo.OTP,
          },
          {
            headers,
          }
        );

        if (!res) {
          console.log("Error");
          setModalValidation(false);
          return;
        }

        setModalPage(4);
        setModalValidation(true);
        saveTrip();
      } catch (error) {
        console.error("An error occur", error);
        setModalValidation(false);
      }
    }
  };

  const saveTrip = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/passenger`,
        {
          ...formData,
          tripId: trip.tripId,
          companyId: trip.companyId,
          customerId: user?.userId || "guest",
        }
      );
      console.log("Registration successful", response.data);
      // navigate("/feed");
      // setFormStatus({
      //   isSubmit: true,
      //   isSucceed: true,
      //   msg: "Your booking has been submitted",
      // });
    } catch (error) {
      console.error("Registration failed", error);
    }
  };

  function handleNextButton() {
    switch (modalPage) {
      case 1:
        console.log(modalPage);
        getCustomerInformation();
        break;
      case 2:
        validateInstallments();
        break;
      case 3:
        validateOTP();
        break;
      default:
        console.log("No further action needed");
    }
  }

  const closeModal = () => {
    toggleModal(false);
    if (modalPage === 4) {
      navigate(`/trip/${trip.tripId}`);
    }
  };

  return (
    <div className={classes.modalContainer}>
      <div className={classes.overlay} onClick={closeModal}></div>
      <div className={classes.modal}>
        <section className={classes.modalHeader}>
          <div className={classes.modalTitleContainer}>
            <img
              src="/assets/img/trip/arrow-left.svg"
              alt="back to customers"
              className={classes.backIcon}
              onClick={() =>
                setModalPage((prev) => (prev > 1 ? prev - 1 : prev))
              }
            />
            <h3 className={classes.modalTitle}>Payment</h3>
          </div>
          <img
            src="/assets/img/trip/close.svg"
            alt="close Modal"
            className={classes.closeIcon}
            onClick={closeModal}
          />
        </section>
        <section className={classes.modalBody}>
          {loadingPage && <Loader />}

          {modalPage === 1 && (
            <>
              <div className="payment-modal-logos">
                <img
                  src={qiCardLogo}
                  alt="Qicard logo"
                  className="qi-card-logo"
                />
                <img
                  src={masterCardLogo}
                  alt="Master Card logo"
                  className="master-card-logo"
                />
              </div>
              <div className="btn-group">
                <button
                  className={`btn ${
                    activeTab === "installments" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("installments")}
                >
                  Installments
                </button>
                <button
                  className={`btn ${activeTab === "direct" ? "active" : ""}`}
                  onClick={() => handleTabChange("direct")}
                >
                  Direct
                </button>
              </div>
              {activeTab === "direct" ? (
                <ErrorBox>
                  Sorry, direct payment is not possible right now.
                </ErrorBox>
              ) : (
                // <p>
                //   Your trip price is:
                //   <span className="trip-price">
                //     {" "}
                //     {parseFloat(trip.tripPrice).toLocaleString()} IQD
                //   </span>
                // </p>
                <p className="trip-price-container">
                  Your trip price is:
                  <span className="trip-price">
                    {" " +
                      (parseInt(trip.tripPrice) / 15).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      ) +
                      " "}
                    IQD
                  </span>
                  <span className="months-number"> x15month</span>
                </p>
              )}

              {/* Remove this condition when re-active the direct payment */}
              {activeTab !== "direct" && (
                <>
                  <div className="tabs-content">
                    <label htmlFor="cardNumber">
                      Type your Qi card or Master card number:
                    </label>
                    {!modalValidation && (
                      <ErrorMessage>You entered an invalid value</ErrorMessage>
                    )}
                    <TextField
                      className="text-input"
                      required
                      placeholder="Your Qi Card or Master card number"
                      name="cardNumber"
                      id="cardNumber"
                      value={customerInfo.cardNumber}
                      onChange={(e) => handleInputChanges(e, 16)}
                      error={!modalValidation}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {modalPage === 2 && (
            <div>
              <p>
                <span className="info-title">Name: </span>
                <span>{customerInfo.fullName}</span>
              </p>
              <>
                <div className="installments-count">
                  <label htmlFor="installmentsMonths">
                    Choose installments count:
                  </label>
                  <Range
                    tripPrice={trip.tripPrice}
                    value={installmentsMonths}
                    setValue={setInstallmentsMonths}
                    checkCustomerAbility={() =>
                      checkCustomerAbility(allowableSalary)
                    }
                    customerAbility={customerAbility}
                  />
                </div>
                {/* <div className="book-button-container">
                  <GradientButton
                    handleClick={validateInstallments}
                    disabled={!customerAbility}
                  >
                    Next
                  </GradientButton>
                </div> */}
              </>
            </div>
          )}

          {modalPage === 3 && (
            <>
              <div>
                <p>
                  We have sent an OTP number to 0{customerInfo.phoneNumber}.
                  Please fill the OTP in the field below:
                </p>
                {!modalValidation && (
                  <ErrorMessage>You entered an invalid value</ErrorMessage>
                )}
                <TextField
                  className="text-input"
                  required
                  placeholder="OTP number"
                  name="OTP"
                  id="OTP"
                  value={customerInfo.OTP}
                  onChange={(e) => handleInputChanges(e, 8)}
                  error={!modalValidation}
                />
              </div>
              {/* <div className="book-button-container">
                <GradientButton handleClick={validateOTP}>Next</GradientButton>
              </div> */}
            </>
          )}

          {modalPage === 4 && (
            <>
              <img
                className="checkImg"
                src="/assets/img/trip/check.svg"
                alt="Booking submitted"
              />
              <h4 className="to-be-removed">Your booking has been submitted</h4>
            </>
          )}
        </section>
        {modalPage < 4 && (
          <section className={classes.modalFooter}>
            <GradientButton handleClick={handleNextButton}>Next</GradientButton>
          </section>
        )}
      </div>
    </div>
  );
}
