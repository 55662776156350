import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ViewTrip.css";
import {
  Card,
  CardContent,
  Container,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { demoTripImage } from "../../assets";

import SafrasLocation from "../../components/Location";
import { useAuth } from "../../hooks/useAuth";
import Modal from "../../components/ui/Modal";
import InformationBox from "../../components/ui/InformationBox";
import classes from "../../components/ui/Modal.module.css";

import Image from "../../components/ui/Image";

import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

export default function ViewTrip() {
  const { id } = useParams();
  const { user, admins, roles } = useAuth();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [pageTwo, setPageTwo] = useState({ active: false, passenger: "" });

  const [trip, setTrip] = useState([]);

  const [passengers, setPassengers] = useState([]);

  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [galleryIndexes, setGalleryIndexes] = useState({
    min: 0,
    current: 1,
    max: trip?.tripImages?.split(",").length - 1,
  });

  const [mainButton, setMainButton] = useState("Book");

  const [installmentAmount, setInstallmentAmount] = useState();
  const [contextMenu, setContextMenu] = useState({ show: false, open: null });
  const [deleteTrip, setDeleteTrip] = useState({
    modalPage: 0,
    confirm: false,
  });

  useEffect(() => {
    setGalleryIndexes((prev) => ({
      ...prev,
      max: trip?.tripImages?.split(",").length - 1,
    }));
  }, [trip]);

  useEffect(() => {
    const filtered = passengers.filter((passenger) =>
      passenger?.firstName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(filtered);
  }, [searchTerm, passengers]);

  const {
    name,
    type,
    desc,
    dateFrom,
    dateTo,
    fromLocation,
    destination,
    price,
    programme,
    offer,
  } = trip;

  useEffect(() => {
    const fetchTrip = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/trip`, {
          headers: {
            id,
          },
        });
        setTrip(response.data || []);
        setInstallmentAmount(
          ((response.data.price * 1.2) / 15).toLocaleString()
        );
      } catch (error) {
        console.error("Failed to fetch Trips", error);
      }
    };
    fetchTrip();
  }, []);

  useEffect(() => {
    const fetchPassengers = async () => {
      try {
        let response;
        const userType = user?.userType;

        if (admins.includes(user?.userType)) {
          response = await axios.get(
            `${process.env.REACT_APP_URL}/trip/${trip?.id}/passengers`,
            {
              params: { userType: user?.userType, userId: user?.userId },
            }
          );
          setMainButton("View Passengers");
          setContextMenu((prev) => ({ ...prev, show: true }));
        } else if (userType === "company") {
          if (user?.userId === trip?.companyId) {
            response = await axios.get(
              `${process.env.REACT_APP_URL}/trip/${trip?.id}/passengers`,
              {
                params: { userType: user?.userType, userId: user?.userId },
              }
            );
            setMainButton("View Passengers");
            setContextMenu((prev) => ({ ...prev, show: true }));
          } else {
            setMainButton("hidden");
          }
        } else {
          setMainButton("Book");
          return;
        }

        setPassengers(response.data);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    fetchPassengers();
  }, [trip, user]);

  function trimedDate(date) {
    return date?.slice(0, date?.indexOf("T"));
  }

  function handleBookButton() {
    setShowModal(true);
  }

  function handleNextImage() {
    if (galleryIndexes.current < galleryIndexes.max) {
      setGalleryIndexes((prev) => ({
        ...prev,
        current: prev.current + 1,
      }));
    } else {
      setGalleryIndexes((prev) => ({
        ...prev,
        current: 0,
      }));
    }
  }

  function handlePreviousImage() {
    if (galleryIndexes.current > galleryIndexes.min) {
      setGalleryIndexes((prev) => ({
        ...prev,
        current: prev.current - 1,
      }));
    } else {
      setGalleryIndexes((prev) => ({
        ...prev,
        current: prev.max,
      }));
    }
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu((prev) => ({
      ...prev,
      open: { mouseX: event.clientX + 5, mouseY: event.clientY - 4 },
    }));
  };

  const handleCloseContextMenu = () => {
    setContextMenu((prev) => ({
      ...prev,
      open: null,
    }));
  };

  const showDeleteModal = () =>
    setDeleteTrip((prev) => ({ ...prev, modalPage: 1 }));

  const handleDeleteTrip = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_URL}/trip/${id}`,
        {
          params: {
            userId: user?.userId,
            userType: user?.userType,
          },
        }
      );
      setDeleteTrip({ confirm: true, modalPage: 2 });
    } catch (error) {
      console.error("Something went wrong");
    }
  };

  const handleCloseModal = () => {
    if (deleteTrip.confirm) {
      navigate("/feed");
    } else {
      setDeleteTrip((prev) => ({ ...prev, modalPage: 0 }));
    }
  };

  // Popup TYPES
  // [1] Customer
  const customerModal = () => {
    return (
      <>
        <h3>Choose an option</h3>
        <div className={classes.buttons}>
          <Link
            to={`/company/${trip?.companyId}/trip/${id}/book`}
            className={classes.button}
          >
            Continue as a guest
          </Link>
          <Link
            to="/register"
            state={{ userType: "customer", tripId: id }}
            className={classes.button}
          >
            Register account
          </Link>
        </div>
      </>
    );
  };

  // [2] Company
  const companyModal = () => {
    return (
      <>
        <h3>Passengers</h3>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {filteredCustomers.length ? (
          <div className="passengersList">
            {filteredCustomers.map((passenger) => {
              return (
                <div
                  key={passenger?.id}
                  className={classes.passenger}
                  onClick={() =>
                    setPageTwo({
                      active: true,
                      passenger: passenger,
                    })
                  }
                >
                  <span className="name">
                    {passenger.firstName.length > 28
                      ? `${passenger.firstName.slice(0, 28)}...`
                      : passenger.firstName}
                  </span>
                  <button className="details-button">Show</button>
                </div>
              );
            })}
          </div>
        ) : (
          <InformationBox>No Passengers</InformationBox>
        )}
      </>
    );
  };

  // [3] Company Page 2
  const pageTwoModal = () => {
    const passengerDetails = pageTwo.passenger;
    return (
      <div className="passenger-data">
        <h3 className="main-title">Passenger info</h3>
        <p>
          <span className="info-title">FullName: </span>
          <span>
            {passengerDetails.firstName} {passengerDetails.middleName}{" "}
            {passengerDetails.lastName}
          </span>
        </p>
        <p>
          <span className="info-title">Passport number: </span>
          <span>{passengerDetails.passportNumber}</span>
        </p>
        <p>
          <span className="info-title">Email: </span>
          <span>{passengerDetails.email}</span>
        </p>
        <p>
          <span className="info-title">Phone: </span>
          <span>{passengerDetails.phoneNumber}</span>
        </p>
        <p>
          <span className="info-title">Gender: </span>
          <span>{passengerDetails.gender}</span>
        </p>
        <p>
          <span className="info-title">Birth: </span>
          <span>
            {passengerDetails.day}/{passengerDetails.month}/
            {passengerDetails.year}
          </span>
        </p>
        <p>
          <span className="info-title">Nationality: </span>
          <span>{passengerDetails.nationality}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="trip-page">
      <Container>
        <h2>
          {type} Trip To: {name}
        </h2>
        <section className="main-trip-content">
          <div className="row-1">
            {trip.tripImages ? (
              <div className="images">
                {trip.tripImages.split(",").map((id, index) => (
                  <Image
                    key={id}
                    imagePath={id}
                    gridCol={
                      index === galleryIndexes.current
                        ? "grid-full"
                        : index === galleryIndexes.current - 1
                        ? `previous grid-single grid-${index}`
                        : index === galleryIndexes.current + 1
                        ? `next grid-single grid-${index}`
                        : `grid-single grid-${index}`
                    }
                    galleryIndexes={galleryIndexes}
                    galleryControllers={
                      index === galleryIndexes.current && (
                        <div className="gallery-controllers">
                          <span
                            className="controller previous"
                            onClick={handlePreviousImage}
                          >
                            <PlayArrowRoundedIcon fontSize="large" />
                            {/* ◀ */}
                          </span>
                          <span
                            className="controller next"
                            onClick={handleNextImage}
                          >
                            <PlayArrowRoundedIcon fontSize="large" />
                          </span>
                        </div>
                      )
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="images">
                <img
                  src={demoTripImage}
                  alt="Demo image"
                  className="grid-full"
                />
              </div>
            )}
            <div className="cards">
              <Card className="card">
                <CardContent className="card-content">
                  <div>
                    <h3 className="card-title">
                      {name}{" "}
                      {contextMenu.show && (
                        <>
                          <img
                            src="/assets/img/company/more-icon.svg"
                            className="more-icon"
                            alt="Edit trip"
                            onClick={handleContextMenu}
                          />
                          <Menu
                            open={contextMenu.open !== null}
                            onClose={handleCloseContextMenu}
                            anchorReference="anchorPosition"
                            anchorPosition={
                              contextMenu.open !== null
                                ? {
                                    top: contextMenu.open.mouseY,
                                    left: contextMenu.open.mouseX,
                                  }
                                : undefined
                            }
                          >
                            <MenuItem
                              className="menu-item"
                              onClick={showDeleteModal}
                            >
                              Delete Trip
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </h3>
                    <Typography color="text.secondary" className="date">
                      {trimedDate(dateFrom)} ▶ {trimedDate(dateTo)}
                    </Typography>
                    <Typography color="text.secondary" className="date">
                      {fromLocation} ▶ {destination}
                    </Typography>
                  </div>
                  <Typography component="p">
                    Traveling is not just about reaching a destination; it's a
                    journey of self-discovery, cultural immersion, and expanding
                    horizons.
                  </Typography>

                  {mainButton !== "hidden" && (
                    <div className="book-and-price">
                      <div className="prices">
                        <div className="installments">
                          <span className="small-text">Starts from</span>
                          <p>
                            <span className="large-text">
                              {installmentAmount}
                            </span>
                            <span className="small-text"> IQD/month</span>
                          </p>
                        </div>
                        <div className="direct-pay">
                          <span className="small-text">Or direct pay </span>
                          <span className="bold-text">
                            {parseInt(price).toLocaleString()} IQD
                          </span>
                        </div>
                      </div>
                      <button
                        className="book-button"
                        onClick={handleBookButton}
                      >
                        {mainButton}
                      </button>
                    </div>
                  )}
                </CardContent>
              </Card>
              <Card className="card">
                <CardContent className="card-content">
                  <div>
                    <h3 className="card-title">Flights</h3>
                    <Typography color="text.secondary" className="date">
                      {trimedDate(dateFrom)} - {trimedDate(dateTo)}
                    </Typography>
                  </div>
                  <div>
                    <div className="partition-title">
                      <h4>Available airlines</h4>
                      <a href="/">Details</a>
                    </div>
                    <div className="row-elements">
                      <img src="/assets/img/trip/airline_01.png" />
                      <img src="/assets/img/trip/airline_02.png" />
                      <img src="/assets/img/trip/airline_03.png" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row-2">
            <section className="trip-details">
              <h3>Description</h3>
              <p>{desc}</p>
            </section>
            <section className="trip-details">
              <h3>Our trip program</h3>
              <div className="trip-programme">
                {programme?.map((day) => (
                  <div key={day.dayNum}>
                    {day.program && (
                      <>
                        <h4>Day {day.dayNum}</h4>
                        <p>{day.program}</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </section>
            <section className="trip-details">
              <h3>Our offer</h3>
              <p>{offer}</p>
            </section>
            {mainButton !== "hidden" && (
              <button
                className="book-button centered"
                onClick={handleBookButton}
              >
                {mainButton}
              </button>
            )}
          </div>
        </section>
      </Container>
      {showModal && (
        <Modal
          toggleModal={setShowModal}
          pageTwo={pageTwo}
          setPageTwo={setPageTwo}
        >
          {roles.includes(user?.userType)
            ? pageTwo.active
              ? pageTwoModal()
              : companyModal()
            : customerModal()}
        </Modal>
      )}
      {deleteTrip.modalPage === 1 ? (
        <Modal toggleModal={handleCloseModal}>
          <h3>Are you sure you want to delete this trip?</h3>
          <div className="modal-buttons">
            <button
              className="modal-button modal-outlined-red"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="modal-button modal-filled-red"
              onClick={() => handleDeleteTrip()}
            >
              Delete
            </button>
          </div>
        </Modal>
      ) : deleteTrip.modalPage === 2 ? (
        <Modal toggleModal={handleCloseModal}>
          <img
            className="modal-checkImg"
            src="/assets/img/trip/check.svg"
            alt="Booking submitted"
          />
          <p className="modal-successful-msg">Trip deleted successfully</p>
        </Modal>
      ) : null}
    </div>
  );
}
