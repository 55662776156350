import { Container, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Modal from "../../components/ui/Modal";
import "./BookTrip.css";
import ErrorMessage from "../../components/ui/ErrorMessage";
import ErrorBox from "../../components/ui/ErrorBox";
// import GradientButton from "../../components/ui/GradientButton";

// import { masterCardLogo, qiCardLogo } from "../../assets";
// import Loader from "../../components/ui/Loader";
// import Range from "../../components/ui/Range";
import PaymentModal from "../../components/ui/PaymentModal";

export default function BookTrip() {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    passportNumber: "",
    email: "",
    phoneNumber: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    nationality: "Iraq",
    address: "",
  });

  const radioButtonsRef = useRef(null);

  const { companyId, tripId } = useParams();

  // const navigate = useNavigate();

  const [days, setDays] = useState(
    Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"))
  );
  const [months] = useState([
    { value: "01", name: "January" },
    { value: "02", name: "February" },
    { value: "03", name: "March" },
    { value: "04", name: "April" },
    { value: "05", name: "May" },
    { value: "06", name: "June" },
    { value: "07", name: "July" },
    { value: "08", name: "August" },
    { value: "09", name: "September" },
    { value: "10", name: "October" },
    { value: "11", name: "November" },
    { value: "12", name: "December" },
  ]);
  const [years, setYears] = useState([]);
  const [nationalities, setNationalities] = useState([]);

  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    paymentModal: false,
    isSucceed: false,
    msg: "",
  });

  const [modalPage, setModalPage] = useState(0);

  // const [activeTab, setActiveTab] = useState("installments");

  const [tripPrice, setTripPrice] = useState(null);

  // useEffect(() => {
  //   if (modalPage === 4) {
  //     setFormStatus((prev) => ({
  //       ...prev,
  //       isSubmit: true,
  //       isSucceed: true,
  //       msg: "Your booking has been submitted",
  //     }));
  //   }
  // }, [modalPage]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = 1900; year <= currentYear; year++) {
      yearOptions.push(year);
    }
    setYears(yearOptions);

    fetch("https://countriesnow.space/api/v0.1/countries/positions")
      .then((res) => res.json())
      .then((data) => setNationalities(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchTrip = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/trip`, {
        headers: {
          id: tripId,
        },
      });

      if (!res) {
        console.error("Cannot get the trip");
        return;
      }

      setTripPrice(res?.data?.price);
    };

    fetchTrip();
  }, []);

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    const selectedYear = document.getElementById("year").value;
    updateDays(selectedMonth, selectedYear);
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    const selectedMonth = document.getElementById("month").value;
    updateDays(selectedMonth, selectedYear);
  };

  const updateDays = (selectedMonth, selectedYear) => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const dayOptions = [];
    for (let i = 1; i <= daysInMonth; i++) {
      dayOptions.push(String(i).padStart(2, "0"));
    }
    setDays(dayOptions);
  };

  const handleFormChanges = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleShowModal = async (e) => {
    e.preventDefault();

    setFormStatus((prev) => ({
      ...prev,
      isSubmit: true,
      paymentModal: true,
    }));

    setModalPage(1);
  };

  // const navigateToTripPage = () => {
  //   navigate(`/trip/${tripId}`);
  // };

  const handleValidation = () => {
    setFormStatus((prev) => ({ ...prev, isSubmit: true }));

    if (!formData.gender) {
      radioButtonsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="booking-trip">
      <Container className="container">
        {formStatus.paymentModal && (
          <PaymentModal
            toggleModal={() =>
              setFormStatus((prev) => ({ ...prev, paymentModal: false }))
            }
            modalPage={modalPage}
            setModalPage={setModalPage}
            formData={formData}
            trip={{
              tripPrice: tripPrice,
              tripId: tripId,
              companyId: companyId,
            }}
          />
        )}

        {formStatus.isSubmit && !formStatus.isSucceed && (
          <ErrorBox>You leave a required field empty</ErrorBox>
        )}
        <h2>Passenger information</h2>

        <form onSubmit={handleShowModal}>
          <div className="input-container" ref={radioButtonsRef}>
            <label>Gender</label>
            {formStatus.isSubmit && !formData.gender && <ErrorMessage />}
            <div className="radio-buttons">
              <div className="button">
                <input
                  type="radio"
                  required
                  name="gender"
                  id="male"
                  value="male"
                  checked={formData.gender === "male"}
                  onChange={handleFormChanges}
                />
                <label
                  htmlFor="male"
                  className={
                    formStatus.isSubmit && !formData.gender
                      ? "invalidOption"
                      : ""
                  }
                >
                  Male
                </label>
              </div>
              <div className="button">
                <input
                  type="radio"
                  required
                  name="gender"
                  id="female"
                  value="female"
                  checked={formData.gender === "female"}
                  onChange={handleFormChanges}
                />
                <label
                  htmlFor="female"
                  className={
                    formStatus.isSubmit && !formData.gender
                      ? "invalidOption"
                      : ""
                  }
                >
                  Female
                </label>
              </div>
            </div>
          </div>
          <div className="input-container">
            <label>First Name</label>
            {formStatus.isSubmit && !formData.firstName && <ErrorMessage />}
            <TextField
              className="text-input"
              required
              name="firstName"
              value={formData.firstName}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.firstName}
            />
          </div>
          <div className="input-container">
            <label>Middle Name</label>
            {formStatus.isSubmit && !formData.middleName && <ErrorMessage />}
            <TextField
              className="text-input"
              required
              name="middleName"
              value={formData.middleName}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.middleName}
            />
          </div>
          <div className="input-container">
            <label>Last Name</label>
            {formStatus.isSubmit && !formData.lastName && <ErrorMessage />}
            <TextField
              className="text-input"
              required
              name="lastName"
              value={formData.lastName}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.lastName}
            />
          </div>

          <div className="input-container">
            <label>Date of birth</label>
            <div className="date-select-boxes">
              <div className="select-container">
                {formStatus.isSubmit && !formData.day && <ErrorMessage />}
                <select
                  id="day"
                  required
                  name="day"
                  value={formData.day}
                  onChange={handleFormChanges}
                  className={
                    formStatus.isSubmit && !formData.day
                      ? "invalidOption"
                      : "validOption"
                  }
                >
                  <option value="">Day</option>
                  {days.map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-container">
                {formStatus.isSubmit && !formData.month && <ErrorMessage />}
                <select
                  id="month"
                  required
                  name="month"
                  value={formData.month}
                  onChange={(e) => {
                    handleMonthChange(e);
                    handleFormChanges(e);
                  }}
                  className={
                    formStatus.isSubmit && !formData.month
                      ? "invalidOption"
                      : "validOption"
                  }
                >
                  <option value="">Month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-container">
                {formStatus.isSubmit && !formData.year && <ErrorMessage />}
                <select
                  id="year"
                  required
                  name="year"
                  value={formData.year}
                  onChange={(e) => {
                    handleYearChange(e);
                    handleFormChanges(e);
                  }}
                  className={
                    formStatus.isSubmit && !formData.year
                      ? "invalidOption"
                      : "validOption"
                  }
                >
                  <option value="">Year</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="input-container">
            <label>Email</label>
            {formStatus.isSubmit && !formData.email && <ErrorMessage />}
            <TextField
              type="email"
              className="text-input"
              required
              name="email"
              value={formData.email}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.email}
            />
          </div>
          <div className="input-container">
            <label>Phone number</label>
            {formStatus.isSubmit && !formData.phoneNumber && <ErrorMessage />}
            <TextField
              className="text-input"
              required
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.phoneNumber}
            />
          </div>
          <div className="input-container">
            <label>Address</label>
            {formStatus.isSubmit && !formData.address && <ErrorMessage />}
            <TextField
              className="text-input"
              required
              name="address"
              value={formData.address}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.address}
            />
          </div>

          <div className="input-container">
            <label htmlFor="country">Nationality</label>
            <select
              id="country"
              required
              name="nationality"
              value={formData.nationality}
              onChange={handleFormChanges}
            >
              {nationalities?.data?.map((el) => (
                <option key={el.iso2} value={el.name}>
                  {el.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label>Passport Number</label>
            {formStatus.isSubmit && !formData.passportNumber && (
              <ErrorMessage />
            )}

            <TextField
              className="text-input"
              required
              name="passportNumber"
              value={formData.passportNumber}
              onChange={handleFormChanges}
              error={formStatus.isSubmit && !formData.passportNumber}
            />
          </div>
          <div className="book-button-container">
            <button
              type="submit"
              className="book-button"
              onClick={handleValidation}
            >
              Book
            </button>
          </div>
        </form>
      </Container>
    </section>
  );
}
