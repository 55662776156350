// src/components/Register.js
import React, { useState, useEffect } from "react";
import { gapi } from "gapi-script";
import {
  TextField,
  Button,
  Container,
  Typography,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

import classes from "./CustomerRegister.module.css";
import { GoogleLoginMethond } from "../../components/googleAuth";
import ErrorBox from "../../components/ui/ErrorBox";
import InformationBox from "../../components/ui/InformationBox";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const CustomerRegister = () => {
  // google auth init
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const [formData, setFormData] = useState({
    userType: "customer",
    username: "",
    email: "",
    password: "",
    gender: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState("");

  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    isSucceed: false,
    msg: "",
  });

  const navigate = useNavigate();

  const [infoBox, setInfoBox] = useState({ msg: "", show: false });

  const handleRegister = async (e) => {
    e.preventDefault();

    if (
      !formData.username ||
      !formData.email ||
      !formData.password ||
      !formData.gender
    ) {
      setFormStatus((prev) => ({ ...prev, isSubmit: true }));
      setError("Please fill in all required fields.");
      return;
    }

    try {
      const registerResponse = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        formData
      );
      console.log("Register successful", registerResponse.data);
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setInfoBox({ msg: "You already have an account", show: true });
      } else if (error.request) {
        console.log(error.request);
        setError("Please, check your connection");
      } else {
        console.log("Error", error.message);
        setError("An error occurred");
      }
    }
  };

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div className={classes.customerRegister}>
          <Typography variant="h4" align="center" gutterBottom>
            Register
          </Typography>
          <form>
            <TextField
              required
              label="User name"
              fullWidth
              error={formStatus.isSubmit && !formData.username}
              margin="normal"
              value={formData.username}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, username: e.target.value }))
              }
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              required
              error={formStatus.isSubmit && !formData.email}
              margin="normal"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              required
              error={formStatus.isSubmit && !formData.password}
              margin="normal"
              value={formData.password}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, password: e.target.value }))
              }
            />

            <TextField
              label="Confirm password"
              type="password"
              fullWidth
              required
              error={
                (formStatus.isSubmit && !confirmPassword) ||
                confirmPassword !== formData.password
              }
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <div
              className={`${classes.inputContainer} ${
                formStatus.isSubmit && !formData.gender && classes.invalidValue
              }`}
            >
              <label className={classes.genderLabel}>Gender*:</label>
              <div className={classes.radioButtons}>
                <div className={classes.button}>
                  <input
                    type="radio"
                    required
                    name="gender"
                    id="male"
                    value="Male"
                    checked={formData.gender === "Male"}
                    onChange={() =>
                      setFormData((prev) => ({ ...prev, gender: "Male" }))
                    }
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className={classes.button}>
                  <input
                    type="radio"
                    required
                    name="gender"
                    id="female"
                    value="Female"
                    checked={formData.gender === "Female"}
                    onChange={() =>
                      setFormData((prev) => ({ ...prev, gender: "Female" }))
                    }
                  />
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>

            <Link to="/agency" className={classes.linkButton}>
              Are you a travel agency?
            </Link>

            {error && <ErrorBox>{error}</ErrorBox>}
            {infoBox.show && (
              <InformationBox>
                {infoBox.msg}{" "}
                <Link to="/login" className={classes.linkButton}>
                  Login
                </Link>
              </InformationBox>
            )}

            <button className={classes.registerButton} onClick={handleRegister}>
              Register
            </button>
          </form>

          <div className={classes.socialLoginContainer}>
            <Typography variant="h5" align="center" gutterBottom>
              or
            </Typography>
            <GoogleLoginMethond />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CustomerRegister;
