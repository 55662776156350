import classes from "./InformationBox.module.css";

export default function InformationBox({ children }) {
  return (
    <div className={classes.InformationBox}>
      <img src="/assets/img/ErrorConfirmation/info.svg" alt="Error" />
      <p>{children}</p>
    </div>
  );
}
