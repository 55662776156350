import classes from "./GradientButton.module.css";
export default function GradientButton({
  full,
  children,
  handleClick,
  disabled,
}) {
  return (
    <div className={full ? classes.fullWidth : classes.fitContent}>
      <button
        className={classes.gradientButton}
        onClick={handleClick}
        type="button"
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}
