import { useState } from "react";
import { useParams } from "react-router-dom";
import { FormControl, TextareaAutosize } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";
import { renderStars } from "./RateStars";
import classes from "./PostRate.module.css";
import axios from "axios";
import InformationBox from "../ui/InformationBox";

export default function PostRate({ lastSection }) {
  const [customerRating, setCustomerRating] = useState(0);
  const [customerReview, setCustomerReview] = useState("");
  const [rateSuccessfully, setRateSuccessfully] = useState(false);

  const { user } = useAuth();

  const { id } = useParams();

  const handleSendingCustomerFeedback = async (rating) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/company/${id}/feedback`,
        {
          data: {
            customerId: user.userId,
            rating,
            // review: customerReview,
          },
        }
      );
      setRateSuccessfully(true);
      // setCustomerReview("");
    } catch (error) {
      console.error(`Failed to send Customer Feedback`);
    }
  };

  const handleCustomerRating = (rating) => {
    setCustomerRating(rating);
    handleSendingCustomerFeedback(rating);
  };

  return (
    <div className={classes.contentRow}>
      <h3 className={classes.rowTitle}>Rate Company:</h3>
      <div className={classes.rankingDiv}>
        <div>{renderStars(5, handleCustomerRating, customerRating)}</div>
        {customerRating ? (
          <span className={classes.rankingPoints}>{customerRating}</span>
        ) : null}
      </div>
      {rateSuccessfully && (
        <InformationBox>Your rating send successfully</InformationBox>
      )}
      {/* {customerRating ? (
        <div className={classes.review}>
          <FormControl fullWidth margin="normal">
            <label htmlFor="customerReview" className={classes.infoTitle}>
              Leave your comment
            </label>
            <TextareaAutosize
              className={classes.textarea}
              placeholder="Type your opinion"
              id="customerReview"
              minRows={3}
              value={customerReview}
              onChange={(e) => setCustomerReview(e.target.value)}
            />
          </FormControl>
          <div className={classes.flexboxEnd}>
            <button
              className={classes.primaryButton}
              onClick={handleSendingCustomerFeedback}
            >
              Submit
            </button>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}
