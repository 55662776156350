import classes from "./ConfirmationBox.module.css";

export default function ConfirmationBox({ children }) {
  return (
    <div className={classes.confirmationBox}>
      <p>{children}</p>
      <img src="/assets/img/ErrorConfirmation/done-all.svg" alt="Error" />
    </div>
  );
}
