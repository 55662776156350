// src/components/Navigation.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  IconButton,
  CssBaseline,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../hooks/useAuth";

const Navigation = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, admins, roles } = useAuth();

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="sticky" sx={{ top: 0, backgroundColor: "#210340" }}>
        <Container sx={{ backgroundColor: "#210340" }}>
          <Toolbar sx={{ backgroundColor: "#210340" }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              SafraTake
            </Typography>
            {admins.includes(user?.userType) ? (
              <Button color="inherit" component={Link} to="/dashboard">
                Dashboard
              </Button>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>

      <div className="divider"></div>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <List sx={{ width: "200px" }}>
          {!user ? (
            <>
              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
              </ListItem>

              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button color="inherit" component={Link} to="/register">
                  Register
                </Button>
              </ListItem>
            </>
          ) : null}

          <ListItem button onClick={() => toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/feed">
              Feed
            </Button>
          </ListItem>
          {roles.includes(user?.userType) ? (
            <ListItem button onClick={() => toggleDrawer(false)}>
              <Button color="inherit" component={Link} to="/safra-page">
                Safra
              </Button>
            </ListItem>
          ) : null}

          {admins.includes(user?.userType) ? (
            <ListItem button onClick={() => toggleDrawer(false)}>
              <Button color="inherit" component={Link} to="/adminPanel">
                Admin Panel
              </Button>
            </ListItem>
          ) : null}

          {user ? (
            <>
              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button
                  color="inherit"
                  component={Link}
                  to={`/profile/${user?.userId}`}
                >
                  Profile
                </Button>
              </ListItem>
            </>
          ) : null}
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
