import { useState } from "react";

export function useImage() {
  const [image, setImage] = useState(null);

  const handlePrepareImage = (event, imageType) => {
    const file = event.target.files[0];

    setImage({ imageFile: file, imageType: imageType });
  };

  return { image, handlePrepareImage };
}
