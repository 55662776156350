import { Link } from "react-router-dom";
import classes from "./CompaniesList.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Container, TextField } from "@mui/material";
import InformationBox from "../../components/ui/InformationBox";

export default function CompaniesList() {
  const [allCompanies, setAllCompanies] = useState([]);
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchAllCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/companies`
        );
        // const response = await axios.get(
        //   "https://safratake-backend.netlify.app/.netlify/functions/fetchCompanies"
        // );
        setAllCompanies(response.data || []);
        setSearchResults(response.data || []);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchAllCompanies();
  }, []);

  useEffect(() => {
    const filtered = filteredCompanies().filter((company) =>
      company.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filtered);
  }, [filter, searchTerm]);

  function filteredCompanies() {
    if (filter === "approved") {
      return allCompanies.filter((company) => company.approved);
    } else if (filter === "pending") {
      return allCompanies.filter((company) => !company.approved);
    } else {
      return allCompanies;
    }
  }

  return (
    <section className={classes.pageContent}>
      <Container>
        <h2 className={classes.mainHeading}>Companies List</h2>

        <div className={classes.filters}>
          <div className={`btn-group ${classes.btnGroup}`}>
            <button
              onClick={() => setFilter("all")}
              className={`btn ${classes.btn} ${
                filter === "all" ? classes.active : ""
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter("approved")}
              className={`btn ${classes.btn} ${
                filter === "approved" ? classes.active : ""
              }`}
            >
              Approved
            </button>
            <button
              onClick={() => setFilter("pending")}
              className={`btn ${classes.btn} ${
                filter === "pending" ? classes.active : ""
              }`}
            >
              Pending
            </button>
          </div>
        </div>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {searchResults.length ? (
          <div className={classes.companiesList}>
            {searchResults.map((company) => (
              <Link
                key={company.id}
                to={`/company/${company.id}`}
                className={classes.company}
              >
                <div>
                  <h3>{company.name}</h3>
                  <a
                    href={`mailto:${company.email}`}
                    className={classes.companyEmail}
                  >
                    {company.email}
                  </a>
                </div>
                <button className={classes.detailsButton}>Show</button>
              </Link>
            ))}
          </div>
        ) : (
          <InformationBox>There is no companies!</InformationBox>
        )}
      </Container>
    </section>
  );
}
