// src/components/Dashboard.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";

import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import classes from "./Dashboard.module.css";
import InformationBox from "../../components/ui/InformationBox";

const Dashboard = () => {
  const auth = useAuth();
  const user = auth.user;

  const [openProducts, setOpenProducts] = useState(false);
  const [users, setUsers] = useState({ customer: [], company: [], admin: [] });
  const totalUsersCount =
    users.customer.length + users.company.length + users.admin.length;

  const [openUsers, setOpenUsers] = useState(false);
  const [openCustomers, setOpenCustomers] = useState(false);
  const [openCompanies, setOpenCompanies] = useState(false);
  const [openAdmins, setOpenAdmins] = useState(false);
  const [customerSearch, setCustomerSearch] = useState("");
  const [adminSearch, setAdminSearch] = useState("");

  // Companies list
  const [allCompanies, setAllCompanies] = useState([]);
  const [filter, setFilter] = useState("all");
  const [companySearch, setCompanySearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchAllCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/companies`
        );
        // const response = await axios.get(
        //   "https://safratake-backend.netlify.app/.netlify/functions/fetchCompanies"
        // );
        setAllCompanies(response.data || []);
        setSearchResults(response.data || []);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchAllCompanies();
  }, []);

  useEffect(() => {
    const filtered = filteredCompanies().filter((company) =>
      company.username.toLowerCase().includes(companySearch.toLowerCase())
    );
    setSearchResults(filtered);
  }, [filter, companySearch]);

  function filteredCompanies() {
    if (filter === "approved") {
      return allCompanies.filter((company) => company.approved);
    } else if (filter === "pending") {
      return allCompanies.filter((company) => !company.approved);
    } else {
      return allCompanies;
    }
  }
  // !End of companies list page

  useEffect(() => {
    console.log("User Token in Dashboard:", user);

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/users`);
        // const response = await axios.get(
        //   "https://safratake-backend.netlify.app/.netlify/functions/fetchUsers"
        // );
        setUsers(response.data || { customer: [], company: [], admin: [] });
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    const fetchAllCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/companies`
        );
        // const response = await axios.get(
        //   "https://safratake-backend.netlify.app/.netlify/functions/fetchCompanies"
        // );
        setAllCompanies(response.data || []);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchUsers();
    fetchAllCompanies();
  }, []);

  const handleToggle = (type) => {
    if (type === "users") {
      setOpenUsers(!openUsers);
    } else if (type === "customer") {
      setOpenCustomers(!openCustomers);
    } else if (type === "company") {
      setOpenCompanies(!openCompanies);
    } else if (type === "admin") {
      setOpenAdmins(!openAdmins);
    } else if (type === "products") {
      setOpenProducts(!openProducts);
    } else if (type === "allCompanies") {
      setOpenCompanies(!openCompanies);
    }
  };

  const filterUsers = (type, companySearch) => {
    return (
      users[type] &&
      users[type].filter((user) => {
        const username = user.username || "";
        const name = (type === "company" && user.name) || "";
        const email = user.email || "";

        return (
          username.toLowerCase().includes(companySearch.toLowerCase()) ||
          email.toLowerCase().includes(companySearch.toLowerCase()) ||
          (type === "company" &&
            name.toLowerCase().includes(companySearch.toLowerCase()))
        );
      })
    );
  };

  return (
    <div className="body-content">
      <Container maxWidth="md">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            Dashboard
          </Typography>

          <Typography
            variant="h5"
            align="left"
            gutterBottom
            onClick={() => handleToggle("users")}
          >
            {openUsers ? "▼" : "▶"} Users ({totalUsersCount})
          </Typography>

          <Collapse in={openUsers}>
            {/* Customers */}
            <Typography
              variant="h5"
              align="left"
              gutterBottom
              onClick={() => handleToggle("customer")}
            >
              {openCustomers ? "▼" : "▶"} Customers ({users.customer.length})
            </Typography>

            <Collapse in={openCustomers}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setCustomerSearch(e.target.value)}
              />
              <List>
                {filterUsers("customer", customerSearch).map((customer) => (
                  <Link
                    to={`/customer/${customer.id}`}
                    key={customer.id}
                    className={classes.listItem}
                  >
                    <div className={classes.listItemDetails}>
                      <h3>{customer.username}</h3>
                      <a
                        href={`mailto:${customer.email}`}
                        className={classes.listItemEmail}
                      >
                        {customer.email}
                      </a>
                    </div>
                    <button className={classes.detailsButton}>Show</button>
                  </Link>
                ))}
              </List>
            </Collapse>

            {/* Companies */}
            <Typography
              variant="h5"
              align="left"
              gutterBottom
              onClick={() => handleToggle("company")}
            >
              {openCompanies ? "▼" : "▶"} Companies ({users.company.length})
            </Typography>

            <Collapse in={openCompanies}>
              <section className={classes.pageContent}>
                <div className={classes.filters}>
                  <div className={`btn-group ${classes.btnGroup}`}>
                    <button
                      onClick={() => setFilter("all")}
                      className={`btn ${classes.btn} ${
                        filter === "all" ? classes.active : ""
                      }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => setFilter("approved")}
                      className={`btn ${classes.btn} ${
                        filter === "approved" ? classes.active : ""
                      }`}
                    >
                      Approved
                    </button>
                    <button
                      onClick={() => setFilter("pending")}
                      className={`btn ${classes.btn} ${
                        filter === "pending" ? classes.active : ""
                      }`}
                    >
                      Pending
                    </button>
                  </div>
                </div>

                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setCompanySearch(e.target.value)}
                />

                {searchResults.length ? (
                  <div className={classes.companiesList}>
                    {searchResults.map((company) => (
                      <Link
                        key={company.id}
                        to={`/company/${company.id}`}
                        className={classes.listItem}
                      >
                        <div className={classes.listItemDetails}>
                          <h3>{company.username}</h3>
                          <a
                            href={`mailto:${company.email}`}
                            className={classes.listItemEmail}
                          >
                            {company.email}
                          </a>
                        </div>
                        <div></div>
                        <button className={classes.detailsButton}>Show</button>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <InformationBox>There is no companies!</InformationBox>
                )}
              </section>
            </Collapse>
            {/* Admins */}
            <Typography
              variant="h5"
              align="left"
              gutterBottom
              onClick={() => handleToggle("admin")}
            >
              {openAdmins ? "▼" : "▶"} Admins ({users.admin.length})
            </Typography>

            <Collapse in={openAdmins}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setAdminSearch(e.target.value)}
              />
              <List>
                {filterUsers("admin", adminSearch).map((user) => (
                  <div key={user.id} className={classes.listItem}>
                    <div className={classes.listItemDetails}>
                      <h3>{user.username}</h3>
                      <a
                        href={`mailto:${user.email}`}
                        className={classes.listItemEmail}
                      >
                        {user.email}
                      </a>
                    </div>
                    <button className={classes.detailsButton}>Show</button>
                  </div>
                ))}
              </List>
            </Collapse>
          </Collapse>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
