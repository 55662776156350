import { useState, useEffect } from "react";

export function useCountries() {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    fetch("https://countriesnow.space/api/v0.1/countries")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch countries data");
        }
        return res.json();
      })
      .then((data) => {
        setCountries(data.data);
      })
      .catch((error) => {
        console.error("Something went wrong", error);
      });
  }, []);

  return {
    countries,
    country,
    setCountry,
    cities,
    setCities,
    city,
    setCity,
    address,
    setAddress,
  };
}
