// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ScrollWrapper from "./components/utils/ScrollWrapper";

import Login from "./components/Login";
import Register from "./Pages/Register/CustomerRegister";
// import Register from "./components/Register";
import AgencyRegister from "./Pages/Register/AgencyRegister";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./Pages/Dashboard/Dashboard";
// import Navigation from "./components/Ar/Navigation"; // * Arabic
import Navigation from "./components/Navigation";
import Safra from "./components/Safra";
import Logout from "./components/Logout";
// import Feed from "./components/Ar/Feed"; // * Arabic
import Feed from "./components/Feed";
// import Feed from "./components/FeedOld";
import { ProvideAuth } from "./hooks/useAuth";
import Location from "./components/Location";
import Footer from "./components/Footer";
import ViewTrip from "./Pages/Trips/ViewTrip";
// import ViewTrip from "./Pages/Trips/ViewTripOld";
import BookTrip from "./Pages/Trips/BookTrip";
import CompaniesList from "./Pages/RegisterCompany/CompaniesList";
import CompanyDetails from "./Pages/RegisterCompany/CompanyDetails";
import Profile from "./Pages/Profile/Profile";
// import Profile from "./Pages/Profile/StaticProfile";
import ViewCompanyProfile from "./Pages/ViewCompanyProfile/ViewCompanyProfile";
import Developer from "./Pages/Developer/Developer";
import ViewCustomerProfile from "./Pages/ViewCustomerProfile/ViewCustomerProfile";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/AdminPanel/CreateUser";
import ExploreTrips from "./Pages/Trips/ExploreTrips";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <ProvideAuth>
        <Navigation />

        <ScrollWrapper>
          <Routes>
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/agency" element={<AgencyRegister />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/dashboard"
              element={
                isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
              }
            />
            <Route path="/profile/:id" element={<Profile />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route
              path="/"
              element={<Feed />}
              exact
              // element={isAuthenticated ? <Feed /> : <Navigate to="/login" />}
            />
            <Route path="/feed" element={<Feed />} />
            <Route path="/safra-page" element={<Safra />} />
            <Route path="/adminPanel/createUser" element={<CreateUser />} />
            <Route path="/adminPanel" element={<AdminPanel />} />
            <Route
              path="/logout"
              element={<Logout setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/company/:companyId/trip/:tripId/book"
              element={<BookTrip />}
            />
            <Route path="/trip/:id" element={<ViewTrip />} />
            <Route path="/registeredCompanies" element={<CompaniesList />} />
            {/* <Route path="/registeredCompanies/:id" element={<CompanyDetails />} /> */}
            <Route path="/company/:id" element={<ViewCompanyProfile />} />
            <Route path="/customer/:id" element={<ViewCustomerProfile />} />
            <Route path="/resetPassword/:id" element={<ForgotPassword />} />
            <Route path="/developer" element={<Developer />} />
            <Route path="/exploreTrips" element={<ExploreTrips />} />
          </Routes>
        </ScrollWrapper>

        <Footer />
      </ProvideAuth>
    </Router>
  );
}

export default App;
