// src/components/Developer.js
import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

import Modal from "../../components/ui/Modal";
import classes from "./Developer.module.css";
import ErrorBox from "../../components/ui/ErrorBox";
import GradientButton from "../../components/ui/GradientButton";

const Developer = ({ setIsAuthenticated }) => {
  const { loginUsing } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      // Check if the user is already authenticated
      const storedToken = localStorage.getItem("token");
      const storedUserId = localStorage.getItem("userId");

      if (storedToken && storedUserId) {
        // setIsAuthenticated(true);
        navigate(`/profile/${storedUserId}`);
      }
    };

    checkAuthAndRedirect();
  }, [navigate]);

  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/devLogin`,
        {
          // const response = await axios.post('https://safratake-backend.netlify.app/.netlify/functions/login', {
          email,
          password,
        }
      );

      const {
        userId,
        token,
        username,
        email: userMail,
        userType,
      } = response.data;

      // Store the token in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("username", username);
      localStorage.setItem("email", userMail);
      localStorage.setItem("userType", userType);
      console.log("Login successful", response.data);
      // setIsAuthenticated(true);
      loginUsing({
        token: token,
        userId: userId,
        username: username,
        userType: userType,
        email: userMail,
      });

      console.log("User ID:", userId);
      console.log("User Token:", token);
      console.log("User Email:", userMail);
      console.log("User Type:", userType);

      navigate("/feed");
    } catch (returnedError) {
      console.error("Login failed", returnedError);
      setError(
        returnedError?.response?.data?.error ||
          "Login failed. Please check your credentials."
      );
    }
  };

  const handleForgetPassword = async () => {
    console.log("Data sent");

    // const res = await axios.post(`${process.env.REACT_APP_URL}/send-email`, {
    //   to: email,
    //   name: "test",
    //   subject: "Reset your password",
    //   message: "Your deliver reset password ",
    // });
  };

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            Developer Gate
          </Typography>
          <form className={classes.loginForm}>
            <TextField
              label="Email"
              type="email"
              error={error}
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
            <TextField
              label="Password"
              type="password"
              error={error}
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
            />
            <button
              className={classes.textButton}
              type="button"
              onClick={() => setForgetPassword(true)}
            >
              Forget your password?
            </button>

            {forgetPassword && (
              <Modal className={classes.modal} toggleModal={setForgetPassword}>
                <h2 className={classes.modalTitle}>Forget password?</h2>
                <h4 className={classes.modalSubTitle}>
                  Please enter your email to receive reset password link
                </h4>
                <div className={classes.inputContainer}>
                  <label className={classes.inputLabel} htmlFor="email">
                    Email:
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <GradientButton handleClick={handleForgetPassword}>
                  Send
                </GradientButton>
              </Modal>
            )}

            {error && <ErrorBox>{error}</ErrorBox>}
            <Button variant="contained" fullWidth onClick={handleLogin}>
              Login
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Developer;
