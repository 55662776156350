import classes from "./Input.module.css";

export default function Input({
  id,
  label,
  type,
  value,
  setValue,
  margin,
  required,
  responsive,
}) {
  return (
    <div
      className={`${classes.inputContainer} ${
        margin === "small"
          ? classes.smallMargin
          : margin === "medium"
          ? classes.mediumMargin
          : ""
      }  ${type === "date" && !value ? classes.returnDate : ""}`}
    >
      <label
        className={`${classes.inputLabel} ${
          responsive ? classes.responsive : null
        }`}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className={type === "date" ? classes.dateInput : null}
        id={id}
        type={type}
        value={value}
        placeholder={label}
        defaultValue={type === "date" && label}
        onChange={(e) => setValue(e.target.value)}
        required={required}
      />
    </div>
  );
}
