import axios from "axios";

const PDFViewer = ({ fileId, fileNum }) => {

  const filteredId = fileId.split("/").pop();

  const getPdfUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/pdfs/${fileId}`,
        {
          responseType: "blob",
        }
      );
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  return (
    <div>
      <button onClick={getPdfUrl}>{fileNum}.pdf</button>
    </div>
  );
};

export default PDFViewer;
