import classes from "./ViewRank.module.css";
import { renderStars } from "./RateStars";

export default function ViewRate({ averageRating, totalRatings, lastSection }) {
  return (
    <div className={classes.contentRow}>
      <div className={lastSection && classes.readingMode}>
        <h3 className={classes.rowTitle}>Rating</h3>
        {renderStars(5, null, averageRating)}
        <p className={classes.description}>{totalRatings} Ratings</p>
      </div>
    </div>
  );
}
